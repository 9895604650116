import { isEmbeddedInStreamingStudio } from './../utils/streamingstudio/index';
import {
  getPageEvents,
  mapStreamTokensToSocialMediaContacts
} from './../advisorHub/utils/facebook';
import { STREAM_TOKENS_ATTR } from './../advisorHub/utils/amplify';
import lodash, { last, orderBy, uniq } from 'lodash';
import { Dispatch } from 'react';
import {
  getElementsInFolder,
  getFoldersByUuidAndContext,
  saveFolder
} from '../advisorHub/clientSideServices/folders';
import {
  cacheCredentials,
  cacheSspCredentials,
  checkChallengeAmplify,
  login,
  signOut
} from '../advisorHub/clientSideServices/login';
import {
  getNudgeByUserId,
  getNudgeContentFavorites,
  getNudgeItems,
  getNudgeTemplates,
  saveNudge,
  saveNudgeContentFavorites,
  saveNudgeTemplates,
  StartKey
} from '../advisorHub/clientSideServices/nudge';
import {
  getSessionsByDate,
  saveSession
} from '../advisorHub/clientSideServices/session';
import { getStoreById } from '../advisorHub/clientSideServices/store';
import { getUser } from '../advisorHub/clientSideServices/user';

import {
  addNewKeyToFolder,
  mapFolderStateToPayload,
  removeKeyFromFolder,
  renameKeyInFolder,
  TRASH_FOLDER,
  FAVORITE_FOLDER,
  SHARED_ITEMS,
  COLLABORATED_ITEMS
} from '../advisorHub/utils/folders';

import {
  DID_ADD_FOLDER_KEY,
  DID_CREATE_FOLDER,
  DID_DELETE_FOLDER_KEY,
  DID_LOAD_FOLDER,
  DID_EDIT_FOLDER_KEY
} from '../advisorHub/utils/analyticsEvents/folder';

import { logEvent } from '../analytics';
import {
  getUserByIdentityId,
  storeUserAssociatedStoreId
} from '../clientSideServices/users';
import { allBrands, isProd } from '../config';
import {
  IAsset,
  IFolder,
  IFolderContext,
  IHubUser,
  IMainState,
  INudge,
  INudgePayload,
  INudgeTemplate,
  ISessionPayload,
  LoginStatus,
  ProgressStatus
} from '../interfaces';
import {
  DID_CREATE_SESSION,
  DID_EDIT_SESSION,
  DID_FAIL_TO_CREATE_SESSION,
  DID_FAIL_TO_EDIT_SESSION,
  DID_FAIL_TO_SAVE_NUDGE_TEMPLATE,
  DID_UPDATE_PROGRESS_STATUS
} from '../utils/constants';
import {
  defaultLoginErrorMessage,
  MissingEntitlementError,
  WrongBrandSiteError
} from '../utils/error';
import { generateV4UUID } from '../utils/identityGenerator';
import { PageView } from '../utils/pageView';
import {
  getCurrentHost,
  getSspControls,
  isOnboardingBrand
} from '../utils/window';
import {
  getCorrectHubHost,
  hubNavTo,
  isWrongHubHost
} from './../advisorHub/utils/hubPagesRoute';
import { ISspCredentials } from './../interfaces/index';
import {
  actionAddUserInfo,
  actionHubLoadNudge,
  actionHubLoadNudgeList,
  actionHubLoadNudgeTemplateList,
  actionHubLoadSessionList,
  actionHubLoadUser,
  actionHubUpdateFavorites,
  actionHubUpdateLoginError,
  actionHubUpdateLoginStatus,
  actionHubUpdateProgressStatus,
  actionHubUpdateSessionFormData,
  actionHubUpdateSessionProgressStatus,
  actionListLibraryAssetsRequest,
  actionListLibraryAssetsSuccessful,
  actionListLibraryAssetsFailed,
  actionLoadFolders,
  actionUpdateElementsInFolderKeys,
  actionUpdateFolderInfo
} from './actions';
import {
  hasCachedAssets,
  SearchAssetOptions,
  searchAssets,
  syncSearchAssetWithCache
} from '../advisorHub/clientSideServices/assetLibrary';
import { Auth } from 'aws-amplify';

const validateEntitlement = () => {
  return getUser().then((user) => {
    if (!user.storeId) {
      throw new MissingEntitlementError('No store entitlement found.');
    }
    return getStoreById(user.storeId).then((store) => {
      if (store.brands?.some((b) => b.brandId === user.brandId)) {
        return user;
      }
      throw new MissingEntitlementError(
        'No matching entitlement between Store and Brand found.'
      );
    });
  });
};

const updateProgressStatus = (
  dispatch: any,
  status: ProgressStatus,
  event?: string,
  error?: any
) => {
  dispatch(actionHubUpdateProgressStatus(status));
  const payload = error ? { status, event, error } : { status, event };
  logEvent(DID_UPDATE_PROGRESS_STATUS, DID_UPDATE_PROGRESS_STATUS, payload);
};

export const actionUpdateNudgeFavoriteItemsAsync = (
  storeId: string,
  brandId: string,
  identityId: string,
  favoriteItems: {
    itemType: string;
    itemId: string;
  }[]
) => {
  return (dispatch) => {
    dispatch(actionHubUpdateFavorites(favoriteItems));
    return saveNudgeContentFavorites(
      storeId,
      brandId,
      identityId,
      favoriteItems
    );
  };
};

export const actionGetNudgeFavoriteItemsAsync = (user: IHubUser) => {
  return (dispatch) => {
    return getNudgeContentFavorites(user?.storeId, user?.id)
      .then((favorites) => {
        dispatch(actionHubUpdateFavorites(favorites?.favouriteItems));
      })
      .catch(console.error);
  };
};

const handleValidUser = async (user: IHubUser, dispatch: Dispatch<any>) => {
  if (user.associatedStoreId) {
    storeUserAssociatedStoreId(user.associatedStoreId);
  }
  let tokens;

  try {
    if (isEmbeddedInStreamingStudio()) {
      tokens = getSspControls()?.getStreamingTokens?.();
    } else {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      tokens = cognitoUser.attributes[STREAM_TOKENS_ATTR];
    }
  } catch (error) {
    tokens = '[]';
    console.error('Fail to get streaming tokens');
  }
  const socialMedia = mapStreamTokensToSocialMediaContacts(tokens);
  dispatch(
    actionHubLoadUser({
      ...user,
      socialMedia
    })
  );
  dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGGED_IN));
};

const validBrandSite = (user: IHubUser) => {
  window['storeId'] = user?.storeId;
  const userBrand = user?.brandId;
  let host = location.hostname;

  if (location.pathname.includes('/bdomain_')) {
    host = last(location.pathname.split('/bdomain_')) + '.inspify.com';
  }

  const isWrongHost =
    isOnboardingBrand() || isProd
      ? isWrongHubHost(host, userBrand)
      : location.pathname !== `/hub/${userBrand}`;
  if (isWrongHost) {
    const correctHubHost = isProd
      ? `https://${getCorrectHubHost(userBrand)}/hub`
      : `${getCurrentHost()}/hub/${userBrand}`;
    return Promise.reject(new WrongBrandSiteError(correctHubHost, user));
  }
  return Promise.resolve(user);
};

export enum AmplifyLoginCode {
  UserNotConfirmedException = 'UserNotConfirmedException'
}
export enum AmplifyChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
}
const handleLoginError = (
  e,
  dispatch,
  username?: string,
  password?: string
) => {
  console.error(e);
  dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGIN_FAILED));
  if (e?.code === AmplifyLoginCode.UserNotConfirmedException) {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.VERIFY));
    return dispatch(actionHubUpdateLoginError(username));
  }
  if (e?.name === 'AmplifyChallengeNameError') {
    if (e.message === AmplifyChallengeName.NEW_PASSWORD_REQUIRED) {
      dispatch(actionHubUpdateLoginStatus(LoginStatus.NEW_PASSWORD_REQUIRED));
      return dispatch(
        actionHubUpdateLoginError(JSON.stringify({ username, password }))
      );
    }
  }
  if (e?.name === 'WrongBrandSiteError') {
    const host = location.hostname;
    const isPreConfigured = allBrands.find(
      (brand) => e.user?.brandId === brand.id
    );
    if ((host?.includes('web-dev') && isPreConfigured)) {
      window.location.replace(e.message);
      dispatch(
        actionHubUpdateLoginError('Redirecting to correct brand site...')
      );
    } else {
      signOut().then(() => {
        dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGGED_OUT));
        dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGIN_FAILED));
        dispatch(actionHubUpdateLoginError(defaultLoginErrorMessage));
      });
    }

    return;
  }

  if (e?.name === 'MissingEntitlementError') {
    dispatch(actionHubUpdateLoginError(e.message));
  } else {
    dispatch(actionHubUpdateLoginError(defaultLoginErrorMessage));
  }
};

export const actionLoginAsync = (username: string, password: string) => {
  return (dispatch) => {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGGING_IN));
    return login(username, password)
      .then(checkChallengeAmplify)
      .then(cacheCredentials)
      .then(validateEntitlement)
      .then(validBrandSite)
      .then((user) => handleValidUser(user, dispatch))
      .catch((e) => handleLoginError(e, dispatch, username, password));
  };
};

export const actionLoginFromSspAsync = () => {
  return (dispatch) => {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGGING_IN));
    try {
      const credentials = JSON.parse(
        getSspControls()?.getCredentials?.()
      ) as ISspCredentials;
      cacheSspCredentials(credentials);
      return validateEntitlement()
        .then((user) => handleValidUser(user, dispatch))
        .catch((e) => handleLoginError(e, dispatch));
    } catch (error) {
      handleLoginError(error, dispatch);
    }
  };
};
export const actionLoginWithValidCachedCredentialsAsync = () => {
  return (dispatch) => {
    return validateEntitlement()
      .then(validBrandSite)
      .then((user) => handleValidUser(user, dispatch))
      .catch((e) => handleLoginError(e, dispatch));
  };
};

export const actionSignOutAsync = (expired = false) => {
  return (dispatch) => {
    return signOut().finally(() => {
      dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGGED_OUT));
      if (expired) {
        dispatch(actionHubUpdateLoginStatus(LoginStatus.LOGIN_FAILED));
        dispatch(
          actionHubUpdateLoginError('Session expired. Please login again.')
        );
      }
    });
  };
};

export const actionSaveNudgeAsync = (nudge: INudge) => {
  return (dispatch, getState) => {
    const userId = (getState() as IMainState).clientState.hub?.user?.id;
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(dispatch, status, 'actionSaveNudgeAsync', error);

    progress(ProgressStatus.LOADING);
    return saveNudge(nudge, userId)
      .then((result) => {
        dispatch(
          actionHubLoadNudge({
            ...result.payload,
            content: nudge.content
          })
        );
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
      })
      .catch((e) => {
        console.error('save nudge error:', e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};


export const actionGetNudgeByUserIdAsync = (
  id: string,
  startKey?: StartKey
) => {
  return (dispatch, getState) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionGetNudgeByUserIdAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    const existingList =
      (getState() as IMainState).clientState.hub?.nudgeList || [];
    return getNudgeByUserId(id, startKey)
      .then((result) => {
        let updatedList = [];
        if (!startKey) {
          updatedList = lodash.uniqBy([...result, ...existingList], 'id');
        } else {
          updatedList = [...existingList, ...result];
        }
        dispatch(actionHubLoadNudgeList(updatedList));
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
      })
      .catch((e) => {
        console.error('error', e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionGetNudgeFromPayloadAsync = (nudgePayload: INudgePayload) => {
  return (dispatch) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionGetNudgeFromPayloadAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    return getNudgeItems(nudgePayload.id, nudgePayload.content)
      .then((result) => {
        dispatch(actionHubLoadNudge({ ...nudgePayload, content: result }));
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
        hubNavTo(PageView.HUB_NUDGES_VIEW);
      })
      .catch((e) => {
        console.error(e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionSaveNudgeTemplateAsync = (nudge: INudge) => {
  return (dispatch, getState) => {
    const userId = (getState() as IMainState).clientState.hub?.user?.id;
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionSaveNudgeTemplateAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    return saveNudgeTemplates(nudge, userId)
      .then((result) => {
        dispatch(
          actionHubLoadNudge({
            ...result.payload,
            content: nudge.content
          })
        );
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
      })
      .catch((e) => {
        console.error('save nudge template error:', e);
        progress(ProgressStatus.FAILED, e);
        logEvent(
          DID_FAIL_TO_SAVE_NUDGE_TEMPLATE,
          DID_FAIL_TO_SAVE_NUDGE_TEMPLATE,
          { error: e }
        );
      });
  };
};

export const actionGetNudgeTemplatesByUserIdAsync = (id: string) => {
  return (dispatch) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionGetNudgeTemplatesByUserIdAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    return getNudgeTemplates(id)
      .then((result) => {
        dispatch(actionHubLoadNudgeTemplateList(result));
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
      })
      .catch((e) => {
        console.error('error get template', e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionGetNudgeTemplateFromPayloadAsync = (
  templatePayload: INudgeTemplate,
  manageTemplate?: boolean
) => {
  return (dispatch) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionGetNudgeTemplateFromPayloadAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    return getNudgeItems(templatePayload.id, templatePayload.content, true)
      .then((result) => {
        dispatch(actionHubLoadNudge({ ...templatePayload, content: result }));
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
        hubNavTo(
          manageTemplate
            ? PageView.HUB_NUDGE_TEMPLATE_MANAGE
            : PageView.HUB_NUDGE_TEMPLATE_VIEW
        );
      })
      .then(() => {
        progress(ProgressStatus.INITIAL);
      })
      .catch((e) => {
        console.error(e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionCreateNudgeFromTemplateAsync = (
  templatePayload: INudgeTemplate
) => {
  return (dispatch) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionCreateNudgeFromTemplateAsync',
        error
      );
    progress(ProgressStatus.LOADING);
    return getNudgeItems(templatePayload.id, templatePayload.content, true)
      .then((result) => {
        dispatch(
          actionHubLoadNudge({
            ...templatePayload,
            content: result
          })
        );
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
        hubNavTo(PageView.HUB_NUDGES_CREATE_FROM_TEMPLATE);
      })
      .catch((e) => {
        console.error(e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionEditNudgeTemplateAsync = (
  templatePayload: INudgeTemplate,
  asNewTemplate?: boolean
) => {
  return (dispatch) => {
    const progress = (status: ProgressStatus, error?: any) =>
      updateProgressStatus(
        dispatch,
        status,
        'actionEditNudgeTemplateAsync',
        error
      );

    progress(ProgressStatus.LOADING);
    return getNudgeItems(templatePayload.id, templatePayload.content, true)
      .then((result) => {
        dispatch(actionHubLoadNudge({ ...templatePayload, content: result }));
      })
      .then(() => {
        progress(ProgressStatus.SUCCESSFUL);
        if (asNewTemplate) {
          hubNavTo(PageView.HUB_NUDGE_TEMPLATE_COPY);
        } else {
          hubNavTo(PageView.HUB_NUDGE_TEMPLATE_EDIT);
        }
      })
      .then(() => {
        progress(ProgressStatus.INITIAL);
      })
      .catch((e) => {
        console.error(e);
        progress(ProgressStatus.FAILED, e);
      });
  };
};

export const actionGetSessionsByDateAsync = (userId: string, date: string) => {
  return (dispatch, getState) => {
    const hubState = (getState() as IMainState)?.clientState?.hub;
    const socialMedias = hubState?.user?.socialMedia || [];
    dispatch(actionHubUpdateSessionProgressStatus(ProgressStatus.LOADING));
    const fetchFacebookEvent = (session) => {
      const promises = socialMedias.map(({ token, id }) =>
        getPageEvents(token, id, date)
      );
      return Promise.all(promises)
        .then((response) => {
          return response.reduce((acc, event) => {
            return [...acc, ...event];
          }, []);
        })
        .then((events) => [...(session?.data || []), ...events])
        .catch(() => session?.data || []);
    };
    return getSessionsByDate(userId, date)
      .then(fetchFacebookEvent)
      .then((sessionList) => {
        dispatch(actionHubLoadSessionList(sessionList || []));
        dispatch(
          actionHubUpdateSessionProgressStatus(ProgressStatus.SUCCESSFUL)
        );
      })
      .catch(() => {
        dispatch(actionHubLoadSessionList([]));
        dispatch(actionHubUpdateSessionProgressStatus(ProgressStatus.FAILED));
      });
  };
};

export const actionSaveSessionsAsync = (
  session: ISessionPayload,
  isEdit?: boolean
) => {
  return (dispatch) => {
    dispatch(actionHubUpdateSessionProgressStatus(ProgressStatus.LOADING));
    const successEventName = isEdit ? DID_EDIT_SESSION : DID_CREATE_SESSION;
    const failedEventName = isEdit
      ? DID_FAIL_TO_EDIT_SESSION
      : DID_FAIL_TO_CREATE_SESSION;
    return saveSession(session)
      .then(() => {
        logEvent(successEventName, successEventName, {
          session_id: session?.id
        });
        dispatch(actionHubUpdateSessionProgressStatus(null));
        dispatch(actionHubUpdateSessionFormData(null));
        window.history.back();
        return;
      })
      .catch((e) => {
        console.error('error', e);
        logEvent(failedEventName, failedEventName, {
          session_id: session?.id
        });
        dispatch(actionHubUpdateSessionProgressStatus(ProgressStatus.FAILED));
      });
  };
};

export const addUserInfoAsync = (ids: string[]) => {
  return (dispatch, getState) => {
    const userInfoState =
      (getState() as IMainState).clientState?.hub?.userInfo || {};
    const updateUserLabelState = (id, alias, email, firstName, lastName) => {
      if (typeof actionAddUserInfo === 'function') {
        dispatch(actionAddUserInfo({ id, alias, email, firstName, lastName }));
      }
    };

    uniq(ids).forEach((id) => {
      if (!userInfoState[id]) {
        return getUserByIdentityId(id)
          .then((user) => {
            updateUserLabelState(
              id,
              user.data.alias,
              user.data.email,
              user.data.first_name,
              user.data.last_name
            );
          })
          .catch(() => {
            updateUserLabelState(id, '', '', '', '');
          });
      }
    });
  };
};

export const setupFolderAsync = (
  uuid: string,
  context: IFolderContext,
  userId: string
) => {
  return (dispatch) => {
    const newFolder = mapFolderStateToPayload({
      folder: {
        keys: [
          { id: generateV4UUID(), key: FAVORITE_FOLDER },
          { id: generateV4UUID(), key: TRASH_FOLDER },
          { id: generateV4UUID(), key: SHARED_ITEMS },
          { id: generateV4UUID(), key: COLLABORATED_ITEMS }
        ]
      },
      uuid,
      userId,
      context
    });

    return saveFolder(newFolder)
      .then(() => {
        dispatch(actionUpdateFolderInfo(newFolder, context));
        logEvent(DID_CREATE_FOLDER, DID_CREATE_FOLDER, {
          folderId: newFolder.id,
          folderContext: context,
          folderUuid: uuid
        });
      })
      .catch((e) => {
        console.error('error', e);
      });
  };
};

export const loadFolderInfoAsync = (
  uuid: string,
  context: IFolderContext,
  userId: string
) => {
  return (dispatch) => {
    if (uuid && context) {
      return getFoldersByUuidAndContext(uuid, context)
        .then((folder) => {
          const sortedFolder = orderBy(
            folder,
            [(f) => new Date(f.createdAt)],
            'asc'
          );
          dispatch(actionLoadFolders(sortedFolder, context));
          logEvent(DID_LOAD_FOLDER, DID_LOAD_FOLDER, {
            folderContext: context,
            folderUuid: uuid
          });

          const myFolder: IFolder = sortedFolder.find((f) => f.uuid === userId);

          if (!myFolder) {
            dispatch(setupFolderAsync(uuid, context, userId));
          } else {
            if (!myFolder.keys.some((k) => k.key === SHARED_ITEMS)) {
              dispatch(
                updateKeysInFolderAsync({
                  key: SHARED_ITEMS,
                  userId,
                  uuid,
                  context,
                  operation: 'add'
                })
              );
            }

            if (!myFolder.keys.some((k) => k.key === COLLABORATED_ITEMS)) {
              dispatch(
                updateKeysInFolderAsync({
                  key: COLLABORATED_ITEMS,
                  userId,
                  uuid,
                  context,
                  operation: 'add'
                })
              );
            }
            if (myFolder.keys.length) {
              const ids = myFolder.keys.map((k) => k.id);
              Promise.all(
                ids.map((id) =>
                  getElementsInFolder({
                    elementType: context,
                    folderId: myFolder.id,
                    keyId: id
                  })
                )
              ).then((results) => {
                const elementsInKeys = {};
                results.forEach((result, idx) => {
                  elementsInKeys[ids[idx]] = result;
                });
                dispatch(actionUpdateElementsInFolderKeys(elementsInKeys));
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
};

export const updateKeysInFolderAsync = ({
  keyId,
  key,
  userId,
  uuid,
  context,
  operation,
  onSuccess,
  onFailed
}: {
  keyId?: string;
  key: string;
  userId: string;
  uuid: string;
  context: IFolderContext;
  operation: 'add' | 'remove' | 'edit';
  onSuccess?: () => void;
  onFailed?: () => void;
}) => {
  return (dispatch, getState) => {
    const folders =
      (getState() as IMainState).clientState.hub?.folders?.[context] || [];
    const folder = folders?.filter((f) => f.uuid === userId)[0];
    const currentFolderKeys = folder?.keys || [];

    const newKeys =
      operation === 'add'
        ? addNewKeyToFolder(key, currentFolderKeys)
        : operation === 'edit'
        ? renameKeyInFolder(key, keyId, currentFolderKeys)
        : removeKeyFromFolder(keyId, currentFolderKeys);

    const payload = mapFolderStateToPayload({
      folder: { ...folder, keys: newKeys },
      userId,
      uuid,
      context
    });

    return saveFolder(payload)
      .then(() => {
        dispatch(actionUpdateFolderInfo(payload, context));
        onSuccess?.();
        const events =
          operation === 'add'
            ? DID_ADD_FOLDER_KEY
            : operation === 'edit'
            ? DID_EDIT_FOLDER_KEY
            : DID_DELETE_FOLDER_KEY;

        const payloadEvents =
          operation === 'add'
            ? {
                folderId: payload.id,
                keyName: key,
                keyId: payload.keys.find((k) => k.key === key)?.id
              }
            : {
                folderId: payload.id,
                keyId,
                keyName: key
              };

        logEvent(events, events, payloadEvents);
      })
      .catch(() => {
        onFailed?.();
      });
  };
};

export const getFolderAsync = (
  uuid: string,
  context: IFolderContext,
  onComplete: (e: Error | null, result?: IFolder[]) => void
) => {
  return (dispatch) => {
    if (uuid && context) {
      return getFoldersByUuidAndContext(uuid, context)
        .then((folder) => {
          const sortedFolder = orderBy(
            folder,
            [(f) => new Date(f.createdAt)],
            'asc'
          );
          dispatch(actionLoadFolders(sortedFolder, context));
          logEvent(DID_LOAD_FOLDER, DID_LOAD_FOLDER, {
            folderContext: context,
            folderUuid: uuid
          });
          onComplete(null, sortedFolder);
        })
        .catch((e) => {
          console.log(e);
          onComplete(e);
        });
    }
  };
};

export const initFolderAsync = (data: {
  uuid: string;
  context: IFolderContext;
  userId: string;
  keys: string[];
}) => {
  const { uuid, userId, context, keys } = data;
  return (dispatch) => {
    const newFolder = mapFolderStateToPayload({
      folder: {
        keys: keys.map((key) => ({
          id: generateV4UUID(),
          key
        }))
      },
      uuid,
      userId,
      context
    });
    return saveFolder(newFolder)
      .then(() => {
        dispatch(actionUpdateFolderInfo(newFolder, context));
        logEvent(DID_CREATE_FOLDER, DID_CREATE_FOLDER, {
          folderId: newFolder.id,
          folderContext: context,
          folderUuid: uuid
        });
      })
      .catch((e) => {
        console.error('error', e);
      });
  };
};

export const updateFolderAsync = (
  input: IFolder,
  userId: string,
  action: string,
  onComplete?: (error?: Error, data?: IFolder) => void
) => {
  return (dispatch) => {
    const newFolder = mapFolderStateToPayload({
      folder: input,
      uuid: input.uuid,
      userId,
      context: input.context
    });

    return saveFolder(newFolder)
      .then(() => {
        dispatch(actionUpdateFolderInfo(newFolder, input.context));
        const events =
          action === 'add'
            ? DID_ADD_FOLDER_KEY
            : action === 'edit'
            ? DID_EDIT_FOLDER_KEY
            : DID_DELETE_FOLDER_KEY;

        logEvent(events, events, {
          folderId: newFolder.id,
          folderContext: input.context,
          folderUuid: input.uuid
        });
        onComplete?.(undefined, newFolder);
      })
      .catch((e) => {
        console.error('error', e);
        onComplete?.(e);
      });
  };
};

export const searchLibAssetsAsync = (
  searchOptions: SearchAssetOptions,
  loadMore?: 'end' | 'start',
  onComplete?: (e: Error | null, result?: IAsset[]) => void
) => {
  return (dispatch, getState) => {
    dispatch(actionListLibraryAssetsRequest(searchOptions));
    return searchAssets(searchOptions)
      .then((data) => {
        const state: IMainState = getState();
        const assetsInStore =
          state.clientState.hub?.assetLibrary?.assets?.data || [];
        const searchSize = searchOptions.size || 20;
        const hasMore = data.assets.length === searchSize;
        const syncedData = syncSearchAssetWithCache(
          searchOptions,
          data,
          assetsInStore
        );
        dispatch(
          actionListLibraryAssetsSuccessful({
            ...syncedData,
            loadMore,
            searchOptions,
            hasMore,
            executeClientSort: hasCachedAssets()
          })
        );
        onComplete?.(null, data?.assets);
      })
      .catch((e) => {
        dispatch(actionListLibraryAssetsFailed(e.message));
        onComplete?.(e);
      });
  };
};
